import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button } from '../../../components/elements/forms'
import Layout from '../../../components/layout'
import { LoadingSpinner } from './potwierdzenie'

const Wrapper = styled.div`
  margin: 50px auto;
  min-height: 50vh;
  text-align: center;
  width: 50%;
`

const Materials = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const email = params.get('email')
  const [isLoading, setIsLoading] = useState(true)
  const [url, setUrl] = useState()

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_MK_API_DOWNLOAD_CLASS_URL}?id=${id}`)
      .then((resp) => {
        setIsLoading(false)
        setUrl(resp.data.url)
      })

    setTimeout(() => {
      setUrl(false)
    }, 60 * 5 * 1000)
  }, [])

  return (
    <Layout>
      <Wrapper>
        {isLoading && (
          <>
            <LoadingSpinner />
            <p>Proszę czekać...</p>
          </>
        )}

        {!isLoading && url && (
          <>
            <p>
              Pobierz materiały edukacyjne i przejdź do realizacji zajęć. Pakiet
              materiałów edukacyjnych znajdziesz pod poniższym linkiem:
            </p>
            <Button href={url}>Kliknij aby pobrać plik</Button>
          </>
        )}

        {!isLoading && !url && (
          <>
            <p>Plik wygasł. Proszę odświeżyć stronę.</p>
            <Button onClick={() => window.location.reload()}>
              Odśwież stronę
            </Button>
          </>
        )}
      </Wrapper>
    </Layout>
  )
}

export default Materials
